<template>
    <!-- 已选 -->
    <div class="select box">
        <!-- <select-title title="已选择"></select-title> -->
        <div class="select-title-wrapper">
            <select-title title="已选择"></select-title>
            <div class="select-title-btn" @click="openGroupDialog" v-if="isGroup">
                <!-- <i class="el-icon-folder-checked"></i> -->
                <img src="@/assets/images/save-group.png">
                <span>存为分组</span>
            </div>
        </div>
        <div class="select-wrapper">
            <!-- 已选择人员 -->
            <div
                v-for="(item, index) of selectTreeNodeData"
                :key="index"
                class="select-item"
                @click="handlerDelTreeNode($event, item)"
            >
                <div class="select-item-content">
                    <img v-if="['student', 'teacher'].includes(item.type)||item.name" :src="personnelImg" alt="personnel">
                    <img v-else :src="departmentImg" alt="department">
                    <span class="title">{{ item.name||item.teacherName }}</span>
                </div>
                <span class="del-wrapper">
                    <img :src="delImg" alt="del">
                </span>
            </div>
        </div>
        <div >
            <el-dialog append-to-body class="add-group" :modal-append-to-body="true" :visible.sync="dialogObj.dialogVisible"  :title="dialogObj.title" :width="dialogObj.width"  @handleClose="handleClose">
                <div class="tip">
                    <i class="el-icon-warning-outline"></i>
                    <span>将已选用户保存分组，下次可直接选用，只对当前用户生效</span>
                </div>
                <div class="form-wrapper">
                    <div class="form-label">请输入分组名称</div>
                    <el-input v-model="form.name" maxlength="15"
                        show-word-limit placeholder="请输入分组名称"></el-input>
                </div>
                <div style="text-align: right;">
                    <el-button type="primary" @click="saveGroup">保存分组</el-button>
                </div>
                <!-- <el-form ref="form" label-position="top" :model="form" label-width="80px">
                    <el-form-item label="分组名称">

                    </el-form-item>
                </el-form> -->
            </el-dialog>
        </div>
    </div>
</template>
<script>
import SelectTitle from "../../components/Title/SelectTitle.vue";
export default {
    name: "TreeShapeSelected",
    components: {
        SelectTitle
    },
    data () {
        return {
            openGroup: false,
            dialogObj:{
                title: '存为分组',
                dialogVisible: false,
                width: '500px'
            },
            form:{
                name:''
            }
        }
    },
    props: {
        selectTreeNodeData: {
            type: Array,
            default: () => {
                return []
            }
        },
        isGroup: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        departmentImg () {
            return require('../../../img/department.png')
        },
        personnelImg () {
            return require('../../../img/personnel.png')
        },
        delImg () {
            return require('../../../img/del.png')
        }
    },
    methods: {
        handlerDelTreeNode(e, item) {
            this.$emit('handlerDelTreeNode', {e: e, item: item})
        },
        openGroupDialog(){
            if(this.selectTreeNodeData.length == 0){
                this.$message.warning('请先选择分组成员')
                return
            }
            this.dialogObj.dialogVisible = true
            this.form.name = ""
        },
        handleClose(){
            this.dialogObj.dialogVisible = false
        },
        /**
         * @Description: 保存分组
         * @Author: 王梦瑶
         * @Date: 2024-09-19 14:30:07
         */
        saveGroup(){

            if(this.form.name){
                let query = {
                    name:this.form.name,
                    selectTreeNodeData:this.selectTreeNodeData,
                }
                this.$emit('saveGroup',query)
                this.dialogObj.dialogVisible = false
            } else {
                this.$message.warning('请输入分组名称')
            }

        }
    },
};
</script>
<style lang="scss" scoped>
.box {
    width: 350px;
    box-sizing: border-box;
    padding: 0 0 0 24px;
}
.select {
    border-left: 1px solid #ddd;
    .select-wrapper {
        width: 100%;
        height: 380px;
        overflow: auto;
        .select-item {
            width: 140px;
            height: 32px;
            margin: 0px 8px 8px 0px;
            box-sizing: border-box;
            padding: 4px 8px 4px 4px;
            font-size: 14px;
            background-color: #F5F5F5;
            border-radius: 3px;
            color: #333;
            display: flex;
            justify-content: space-between;
            float: left;
            .select-item-content {
                display: flex;
                align-items: center;
                .title {
                    max-width: 80px;
                    margin-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .del-wrapper {
                width: 8px;
                height: 8px;
                cursor: pointer;
            }
        }
    }
}
.select-title-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    .select-title-btn{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #3C7FFF;
        cursor: pointer;
        display: flex;
        align-items: center;
        img{
            width: 19px;
            height: 15px;
            margin-right: 8px;
        }
    }
}
.tip{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #6D6F73;
    i{
        margin-right: 5px;
    }
}
.form-wrapper{
    margin-bottom: 30px;
    .form-label{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #2B2E33;
        margin-bottom: 10px;
        margin-top: 11px;
    }
}
</style>
<style lang="scss">
.add-group{
    .el-dialog__body{
        padding-top: 0px !important;
    }
}
</style>
